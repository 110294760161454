import { GatsbySeo } from 'gatsby-plugin-next-seo'

import EcoYoga from '../../components/Campanha/EcoYoga'

function EcoConcepcaoYoga() {
  return (
    <>
      <GatsbySeo title="Campanha | Decathlon" />
      <EcoYoga />
    </>
  )
}

export default EcoConcepcaoYoga
